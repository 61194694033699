import React, { Component, useContext, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Textarea from '../Controls/Textarea';
import { Map } from 'immutable';
import Input from '../Controls/Input';
import './Styles/settings.scss';
import swal from "sweetalert2";
import { updatePassword, updateSocialDetails } from '../../Actions/Freelancers';
import PWAContext from '../../PWA/Context/PWAContext';
import AuthenticationContext from '../../Authentication/Context/AuthenticationContext';
import { startRegistration, startAuthentication } from '@simplewebauthn/browser';

import { passkeyRegistrationStart, passkeyRegistrationEnd } from '../../Authentication';


function PasskeyButton() {

    const buttonRef = useRef(null);


    const { isPWA } = useContext(PWAContext);
    const { loginInfo } = useContext(AuthenticationContext);
    const { userToken } = loginInfo;

    const is2FAAuthenticated = loginInfo?.is2FAuthenticated;
    
    const onPasskeyRegistration = async () => {


        let registrationStart = await passkeyRegistrationStart();
        let credential = await startRegistration(registrationStart);
        let registrationResponse = await passkeyRegistrationEnd(credential);
        localStorage.setItem('lastPasskeyTzf', JSON.stringify({ email: userToken.email }))
        return true;

    }

    useEffect(() => {
        if(buttonRef.current) {
            buttonRef.current.addEventListener('click', async () => {
                await onPasskeyRegistration();
                return true;
            });
    
            return () => {
                buttonRef.current.removeEventListener('click', () => {});
            }
        }
    }, [isPWA, userToken])

    const passkeyEnabled = isPWA && is2FAAuthenticated;

    if(!passkeyEnabled) {
        return null;
    }

    return <div className="settings-block">
        <h2>Passkey</h2>
        <div className="">

            { is2FAAuthenticated && <div>
                2FA is ingeschakeld op dit account
            </div> }
            {
                isPWA && <div>
                    Deze applicatie draait zelfstandig
                </div>
            }

        </div>
        <button className="p-2 bg-blue-500 text-white disabled:bg-red-500" disabled={false} ref={buttonRef}>Passkey instellen</button>
    </div>
        

}

export default function Settings() {

    const freelancer = useSelector((state) => state.getIn(['freelancers','single']));



    if(!freelancer) {
        return null;
    }

    return <div className="card settings">
        <div className="card-header">
            <h1>Instellingen</h1>
        </div>
        <div className="card-body">
             <FreelancerDescription freelancer={freelancer} />
             <PasskeyButton />
             <PasswordChange freelancer={freelancer} />
        </div>
       
    </div>

}

export function FreelancerDescription({ freelancer }) {

    let headers = freelancer.getIn(['socialDetails','description'], "").match(/\<h4\>.*?\<\/h4\>/gms);
    let answers = freelancer.getIn(['socialDetails','description'], "").match(/\<p\>.*?\<\/p\>/gms);

    console.log("description", freelancer.getIn(['socialDetails','description'], ""))

    console.log("header", headers);
    console.log("answers", answers);
    
    let info = Map();
    (headers || []).forEach((header, index) => {
        if(header.match('Introductie van jezelf')) {
            info = info.set('Introductie van jezelf', (answers[index] || "").replace(/\<p\>(.*?)\<\/p\>/ms, '$1'))
        }

        if(header.match('Opleiding en werkervaring')) {
            info = info.set('Opleiding en werkervaring', (answers[index] || "").replace(/\<p\>(.*?)\<\/p\>/ms, '$1'))
        }

        if(header.match('Werkervaring bij USC')) {
            info = info.set('Werkervaring bij USC', (answers[index] || "").replace(/\<p\>(.*?)\<\/p\>/ms, '$1'))
        }
    })

    const [introduction, setIntroduction] = useState(info.get('Introductie van jezelf',''));
    const [schooling, setSchooling] = useState(info.get('Opleiding en werkervaring',''));
    const [workExperience, setWorkExperience] = useState(info.get('Werkervaring bij USC',''));
    const dispatch = useDispatch();

    const saveFreelancerDescription = () => {
        
        if(!freelancer.getIn(['socialDetails'])) {
            freelancer = freelancer.set('socialDetails', Map());
        }
        let description = `
            <h4>Introductie van jezelf</h4><p>${introduction}</p>
            <h4>Opleiding en werkervaring</h4><p>${schooling}</p>
            <h4>Werkervaring bij USC</h4><p>${workExperience}</p>
        `;
        freelancer = freelancer.setIn(['socialDetails','description'], description);

        dispatch(updateSocialDetails(freelancer)).then(() => {
            swal.fire({
                title: 'Gelukt!',
                html: `De informatie over jezelf is gewijgd.`,
                type: 'success'
            })
        }).catch(() => {
            swal.fire({
                title: 'Helaas!',
                html: `Er heeft zich een fout voorgedaan bij het opslaan, mocht dit zich vaker voordoen neem dan contact op met u staat centraal`,
                type: 'success'
            })
        })

    }
    
    return <div className="settings-block">
        <h2>Over jezelf</h2>
        <h3>Introductie van jezelf</h3>
        <Textarea value={introduction} onChange={(value) => setIntroduction(value)} />
        <h3>Opleiding en werkervaring</h3>
        <Textarea value={schooling} onChange={(value) => setSchooling(value)} />
        <h3>Werkervaring bij USC</h3>
        <Textarea value={workExperience} onChange={(value) => setWorkExperience(value)} />
        <button className="btn btn-success pull-right" onClick={() => saveFreelancerDescription()}>Opslaan</button>
    </div>

}


export function PasswordChange({ freelancer }) {

    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const dispatch = useDispatch();

    const savePassword = () => {
        
        let correctLength = password.length > 7;
        let usedCapitals = password.match(/[A-Z]+/);
        let usedDigits = password.match(/[0-9]+/);
        let passwordsAreEqual = password == passwordRepeat;

        if(correctLength && usedCapitals && usedDigits && passwordsAreEqual) {
            
            dispatch(updatePassword(freelancer.set('setPassword', password))).then(() => {
                swal.fire({
                    title: 'Je wachtwoord is gewijzigd',
                    html: `Je wachtwoord is successvol gewijziged`,
                    type: 'success'
                })
            }).catch(() => {
                swal.fire({
                    title: 'Er heeft zich een fout voorgedaan',
                    html: `Er heeft zich een fout voorgedaan bij het wijzigen van jouw wachtwoord, gebeurd dit vaker neem dan contact op met u staat centraal`,
                    type: 'error'
                })
            })

        } else {
             swal.fire({
                title: 'Je wachtwoord is niet correct',
                html: `<ul style='text-align:left'>
                    ${!passwordsAreEqual && "<li>Je twee ingevulde wachtwoorden komen niet overeen</li>" || ""}
                    ${!correctLength && "<li>Je wachtwoord moet minimaal 8 tekens bevatten</li>" || ""}
                    ${!usedDigits && "<li>Je wachtwoord moet minimaal 1 cijfer bevatten</li>" || ""}
                    ${!usedCapitals && "<li>Je wachtwoord moet minimaal 1 hoofdletter bevatten</li>" || ""}
                    </ul>
                `,
                type: 'error'
            })
        }
    }

    return <div className="settings-block">
        <h2>Wachtwoord wijzigen</h2>
        <div className="form">
            <div className="form-row">
                <label className="form-label" for="password">Nieuw wachtwoord:</label>
                <Input 
                    onChange={(value) => setPassword(value)} value={password}
                    name="password" id="password" type="password" className="form-control" />
            </div>
            <div className="form-row">
                <label className="form-label" for="password-repeat">Nieuw wachtwoord nogmaals:</label>
                <Input 
                    onChange={(value) => setPasswordRepeat(value)} value={passwordRepeat}
                    name="password-repeat" id="password-repeat" type="password" className="form-control" />
            </div>
            <div className="form-row">
                <label className="form-label">&nbsp;</label>
                <button className="btn btn-success" onClick={() => savePassword()}>Wachtwoord wijzigen</button>
            </div>
        </div>
    </div>

}

// Omschrijving 
    // Introductie van jezelf
    // Opleiding en werkervaring
    // Werkervaring bij USC
// Profielfoto toevoegen/wijzigen
// Wachwoord wijzigen

// Structuur freelancers aanpassen
    // Ruimte voor omgeschrijving
    // Ruimte voor profiel plaatje

// Nieuwe beschermde folder aanmaken voor
// profielfoto's van freelancers

// "<h4>Kop 1</h4><p>Beschrijving</p><h4>Kop 2</h4><p>Beschrijving 2</p><h4>Kop 3</h4><p>Beschrijving 3</p>".match(/\<h4\>.*?\<\/h4\>/g)
// "<h4>Kop 1</h4><p>Beschrijving</p><h4>Kop 2</h4><p>Beschrijving 2</p><h4>Kop 3</h4><p>Beschrijving 3</p>".match(/\<p\>.*?\<\/p\>/g)